
/**
 * wrapper for API-provided program application DTO
 */
class ApplicationInfo {

    /**
     * 
     * @param {*} data raw ApplicationDto from API
     */
    constructor(data) {
        this._data = data;
    }

    get programCode(){
        return this._data?.ProgramCode;
    }

    get year(){
        return this._data?.Year;
    }

    get isStarted(){
        return this._data?.IsStarted;
    }

    /**
     * is data still loading
     * @returns {Boolean}
     */
    get isLoading(){
        if (!this._data)
            return true;
        return false;
    }
    /**
     * actual student deadline
     * @returns {Date}
     */
    get deadline() {
        if (this._data == null)
            return null;

        return Date.parse(this._data.Deadline);
    }

    get source(){
        return this._data?.Source;
    }

    /**
     * convenience method to check for passed deadlines
     * @returns {Boolean}
     */
    get isDeadlinePassed(){
        return this.deadline < Date.now();
    }
        
    /**
     * 
     */
    get isSubmitted(){
        return this._data?.IsSubmitted === true;
    }

    get isRenewal(){
        return this.source === 'Renew';
    }

    get awardStatus(){
        return this._data?.AwardSummaryStatus;   
    }

    get reviewStatus(){
        return this._data?.ReviewStatus;   
    }

    get reviewMessage(){
        return this._data?.ReviewStatusDescription;
    }

    get reviewInstructions(){
        return this._data?.ReviewInstructions;
    }
   
    get declineableRenewalStatuses(){
        let statuses = ['RNWEMSENT', 'HVFYFNDS', 'RNW'];
        return statuses;   
    }

    get isAwardOffer(){
        return this.awardStatus === 'Offer';        
    }

    get isAwarded() {
        return this.awardStatus === 'Authorized' || this.awardStatus === 'Hold'; 
    }

    /**
     * can student unapply from the fund
     */
    get isRemovable() {
        return this.source === 'Apply' && this.awardStatus === 'None'; 
    }

}

export default ApplicationInfo;