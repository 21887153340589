import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { AnswerTypeAddressProps } from '../../store/form/fields';
import { compositeFieldname } from '../../utils';
import apiClient from '../../utils/API';

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const AddressPicker = ({ name, only = '', children }) => {

    const methods = useFormContext({ mode: 'onBlur' });
    const { watch } = methods;

    const [statesOriginal, setStatesOriginal] = useState([]);
    const states = statesOriginal.map(state => ({
        label: state.Name,
        value: Number(state.Id),
        stateCode: state.StateCode,
    }));

    const [counties, setCounties] = useState([]);
    const [cities, setCities] = useState([]);
    const noCounties = (counties.length === 0);


    const state = watch(compositeFieldname(name,AnswerTypeAddressProps.STATE));
    const county = watch(compositeFieldname(name,AnswerTypeAddressProps.COUNTY));
    const city = watch(compositeFieldname(name,AnswerTypeAddressProps.CITY));
    const zip = watch(compositeFieldname(name,AnswerTypeAddressProps.ZIP));
    const streetAddress = watch(compositeFieldname(name,AnswerTypeAddressProps.ADDRESS));

    const getValue = (value) => {
        if(typeof value === 'object' && value) return value?.value;
        return value;
    }

    useEffect(()=>{
        if(only && only !=='state') return;

        (async ()=>{           
            try{
                const states = await apiClient.get('/state-and-territory/states-and-territories');
                states.unshift({
                    "Id": -1,
                    "StateCode": null,
                    "AnsiCode": null,
                    "Abbreviation": null,
                    "Name": "Unlisted/Foreign Country"
                })
                setStatesOriginal(states)            
            } catch(err){
                console.error(err);
            }
        })();
    },[]);

    useEffect(()=> {
        if (only && only !=='county') return;

        if (!state) return;

        (async ()=>{
            
            try {                           
                const counties = await apiClient.get('/county/counties', { "StateId": state.value });
                                              
                setCounties(counties.map(county => {
                    return {
                        label: county.CountyName,
                        value: Number(county.Id)
                    }
                }))

                // -1 lets us indicate unlisted is selected
                if(state?.value == null) {
                    state.value = -1
                }
                // -1 lets us indicate unlisted is selected
                if (city && city?.value == null) {
                    city.value = -1
                }

            } catch(err){
                console.error(err);
            }
        })();
    },[state])

    useEffect(()=>{
        if (only && only !=='city') return;

        if (!county && !noCounties) return;

        (async ()=>{
           
            try {
           
                let cities = []
                if (noCounties) {
                    const stateCode = getValue(state);
                    const chosenState = statesOriginal.find(state => parseInt(state.Id) === stateCode)
              
                    cities = await apiClient.get(`/city/search?lookup.stateAbbreviation=${chosenState.Abbreviation}`)
                } else {
                    cities = await apiClient.get(`/city/cities/${getValue(county)}`);
                }

                cities.unshift({
                    "Id": -1,
                    "Name": "Unlisted City",
                    "State": null
                })
                setCities(cities.map(city => {
                    return {
                        label: city.Name,
                        value: Number(city.Id)
                    }
                }))
            } catch(err){
                console.error(err);
            }
        })();
    },[county, state]);

    return (
        <>
            {children({
                states,
                counties,
                cities,
                streetAddress,
                state,
                county,
                city,
                zip,
                noCounties,
            })}
        </>
    );
}

export default AddressPicker;
