import { FIELDS } from "../../../store/form/fields";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import { useState } from "react";
import NumericDecimal from "../../../components/NumericDecimal";
import Checkbox from "../../../components/Checkbox";


/**
 * prompt for number of credits per term, as is appropriate for the school they said they would attend
 * @param {*} param0.section 
 * @param {*} param0.season 
 * @param {*} param0.includeSummer 
 * @returns 
 */
function CreditsPerTerm({ section, season, includeSummer = false, termType="Q" }) {

    const { errors, getValues, setValue, handleSubmit, watch } = useFormContext({ mode: "onBlur", });
    const activeYear = section.year;

    const isQuarterCollege = termType !== "S";

    class TermAttendance {

        constructor(state, year, name, term, offset = 0, isActive = true) {
            this._state = state;
            this._term = term;
            this._year = year;
            this._name = name;
            this._offset = offset;
            this._isActive = isActive;
        }

        /**
         * @returns {number|string}
         */
        get creditHours() {
            if (this._state[0] !== null)
                return this._state[0];
            return section.getAnswerByField(this.name)?.Response;
        }
        /**
         * @param {number|string} value
         */
        set creditHours(value) {
            this._state[1](value);
            setValue(this.name, value);
        }


        get isActive() {
            return this._isActive;
        }

        /**
         * display of term
         */
        get term() {
            return `${this._term} ${this._year}`;
        }

        /**
         * the field name
         */
        get name() {
            return this._name;
        }

        get deadline() {
            const event = season?.getEvent("EndDate", this._term);
            if (!event)
                return null;
            return moment(event.date).subtract(this._offset, 'years').format('MM/DD/YYYY');
        }


        get isNotAttending() {
            return this.creditHours === 0 || this.creditHours === "0";
        }

        set isNotAttending(value) {
            if (value)
                this.creditHours = 0;
            else
                this.creditHours = "";
        }

        get isTermAnswered() {
            var credits = watch(this.name);
            return credits >= 0;
        }

    }

    const listTerms = [
        new TermAttendance(useState(null), activeYear, FIELDS.NATIONAL_GUARD__CREDITS_PRESUMMER, 'Summer', 1, includeSummer),
        new TermAttendance(useState(null), activeYear, FIELDS.NATIONAL_GUARD__CREDITS_FALL, 'Fall'),
        new TermAttendance(useState(null), activeYear + 1, FIELDS.NATIONAL_GUARD__CREDITS_WINTER, 'Winter', 0, isQuarterCollege),
        new TermAttendance(useState(null), activeYear + 1, FIELDS.NATIONAL_GUARD__CREDITS_SPRING, 'Spring'),
        new TermAttendance(useState(null), activeYear + 1, FIELDS.NATIONAL_GUARD__CREDITS_POSTSUMMER, 'Summer', 0, includeSummer)
    ];

    return (
        <div className="table-wrapper">
            <table className="table">
                <thead>
                    <tr>
                        <th>Term</th>
                        <th>Anticipated Credit Hours</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {listTerms.filter(x => x.isActive).map(item => {
                        const fieldTermName = item.name;
                        const isNotAttendingFieldName = "IsNotAttending" + item.name;

                        return (
                            <tr>
                                <td>{item.term}<br /><small>Application Deadline: {item.deadline}</small></td>
                                <td>
                                    <NumericDecimal
                                        name={fieldTermName}
                                        placeholder="Credit Hours"
                                        precision={1}
                                        value={item.creditHours} // Pass credit hours value as prop
                                        onChange={(event) => item.creditHours = event.target.value}
                                    />
                                </td>
                                <td>
                                    <Checkbox
                                        name={isNotAttendingFieldName}
                                        label="Not attending"
                                        value={item.isNotAttending || false} // Pass checkbox value as prop
                                        disabled={item.creditHours > 0}
                                        onChange={(isChecked) => item.isNotAttending = isChecked}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default CreditsPerTerm;